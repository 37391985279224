import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import yahoo_calendar_1 from '../../../../../../../common/src/assets/image/yahoo_calendar_1.png';
import yahoo_calendar_3 from '../../../../../../../common/src/assets/image/yahoo_calendar_3.png';
import yahoo_calendar_2 from '../../../../../../../common/src/assets/image/yahoo_calendar_2.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing events that are defined in Yahoo! Calendar. Here's how:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Yahoo! Calendar </strong>option.
          The <strong>Add Calendar Source - Yahoo! Calendar </strong>window will
          appear.
          <br />
          <Image alt="" src={yahoo_calendar_1} style={{ width: 690 }} />
        </li>
        <li>
          Open{' '}
          <a
            href="https://calendar.yahoo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://calendar.yahoo.com/
          </a>{' '}
          in your web browser. Log into your account if needed.
        </li>
        <li>
          Click the drop-down arrow to the right of the calendar you'd like to
          add. Select <strong>Edit Calendar</strong> from the drop-down menu.
          <br />
          <Image alt="" src={yahoo_calendar_3} style={{ width: 275 }} />
        </li>
        <li>
          Click the <strong>Get sharable link</strong> option.
        </li>
        <li>
          Right-click the URL under{' '}
          <strong>To import into a Calendar app (ICS)</strong>.
        </li>
        <li>
          Copy the calendar URL by clicking the{' '}
          <strong>Copy link address</strong> command on the popup menu.
          Depending on your web browser, this command might be named{' '}
          <strong>Copy shortcut</strong> or <strong>Copy Link Location</strong>
          .​
          <br />
          <Image alt="" src={yahoo_calendar_2} style={{ width: 723 }} />
        </li>
        <li>
          After copying the link, the address field at the top of the{' '}
          <strong>Add Calendar Source - Yahoo! Calendar </strong>window should
          be automatically filled. If that doesn't happen, right-click the
          address field and select <strong>Paste</strong> from the popup menu,
          or press <strong>Ctrl+V</strong>.​
        </li>
        <li>
          Enter the name of your Yahoo! Calendar in the field below the calendar
          address field.​
        </li>
        <li>
          ​The OK button will now be enabled. Click the OK button to add your
          Yahoo! Calendar to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Yahoo-Calendar"
      commentsId="commentsplus_post_145_489"
      title="Print Yahoo! Calendar"
      description="PrintableCal can create printable calendars containing events that are defined in Yahoo! Calendar."
      keywords="Yahoo Calendar, print Yahoo Calendar, import Yahoo Calendar, print Yahoo events, printable calendar, calendar template, Excel calendar, Word calendar"
      content={content}
    />
  );
};

export default Documentation;
